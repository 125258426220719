import React from 'react';
import { Navbar, Image} from 'react-bootstrap';
import indexIcon from '../img/index.svg';

function Header() {
  return (
    <Navbar expand="lg">
      <Navbar.Brand>

        <Image src={indexIcon} alt="Index Icon" width="50px" />
        &nbsp;
        gnazar.io - IPFS
        &nbsp;
        -
        &nbsp;
        <a href="https://gnazar.io">
        HTTPS
        </a>
      </Navbar.Brand>
    </Navbar>
  );
};
export default Header;
